import { useEffect, useState, useCallback } from 'react';
import CopyIcon from '../icons/CopyIcon.js';

export const getTruncatedWalletAddress = (address, visibleCount = 4) => {
  if (!address) {
    return '';
  }
  return `${address?.substring(0, visibleCount)}...${address?.substring(
    address.length - visibleCount,
    address.length
  )}`;
};

const Tooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onClick = useCallback(() => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 1000);
  }, []);

  return (
    <div className="flex flex-wrap">
      <div className="w-full text-center relative">
        <div onClick={onClick}>{children}</div>
        {showTooltip && (
          <div
            id="tooltip"
            className="absolute bg-white border-0 mt-2 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
          >
            <div>
              <div className="bg-gray-900 text-white p-3 mb-0 uppercase rounded shadow">{text}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DisplayAddress = ({ address, shouldTruncate }) => {
  const [trucatedAddress, setTruncatedAddress] = useState('');

  useEffect(() => {
    if (address.length < 6) {
      setTruncatedAddress(address);
    } else {
      setTruncatedAddress(getTruncatedWalletAddress(address));
    }
  }, [address]);

  const onCopy = useCallback(() => navigator.clipboard.writeText(address), [address]);

  return (
    <div className="flex cursor-pointer">
      <a className="hidden md:block " onClick={onCopy}>
        {address}
      </a>
      <a className="md:hidden" onClick={onCopy}>
        {trucatedAddress}
      </a>
      <Tooltip text="Copied">
        <CopyIcon onClick={onCopy} className="w-6 h-6 ml-2" />
      </Tooltip>
    </div>
  );
  return;
};

export const COMMUNITY_ADDRESS = 'AntsWeFmBdXe8spEEBGruxVN1rBYHPLARW2awntWsrbj';
export const DEVELOPER_ADDRESS = 'DevsG7HXg389pJhKjnF53L7cSwq6PrsRF6nyEdyUeczK';

export const CommunityAddress = ({ shouldTruncate = true }) => (
  <DisplayAddress address={COMMUNITY_ADDRESS} shouldTruncate={shouldTruncate} />
);

export const DeveloperAddress = ({ shouldTruncate = true }) => (
  <DisplayAddress address={DEVELOPER_ADDRESS} shouldTruncate={shouldTruncate} />
);
