import { useState, useEffect, useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue, useRecoilCallback } from 'recoil';
import * as anchor from '@project-serum/anchor';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { getCandyMachineState } from '../mint/candyMachine.js';

const candyMachineStateAtomState = atom({
  key: 'candyMachineStateAtomState',
  default: null,
});

const candyMachineConfigurationAtomState = atom({
  key: 'candyMachineConfigurationAtomState',
  default: {
    config: undefined,
    treasury: undefined,
    connection: undefined,
    candyMachineId: undefined,
  },
});

const useRefreshCandyMachineStateCallback = () => {
  const wallet = useAnchorWallet();
  return useRecoilCallback(
    ({ snapshot, set }) => () => {
      const { connection, candyMachineId } = snapshot
        .getLoadable(candyMachineConfigurationAtomState)
        .getValue();

      if (!(wallet && candyMachineId && connection)) {
        return;
      }
      getCandyMachineState(wallet, candyMachineId, connection)
        .then((candyMachineState) => {
          console.log(candyMachineState);
          set(candyMachineStateAtomState, candyMachineState);
        })
        .catch((error) => console.error(error));
    },
    [wallet]
  );
};

const CandyMachineProvider = () => {
  const wallet = useAnchorWallet();
  const [candyMachineState, setCandyMachineState] = useRecoilState(candyMachineStateAtomState);
  const [candyMachineConfiguration, setCandyMachineConfiguration] = useRecoilState(
    candyMachineConfigurationAtomState
  );
  const refreshCandyMachineState = useRefreshCandyMachineStateCallback();

  useEffect(() => {
    setCandyMachineConfiguration({
      config: new anchor.web3.PublicKey(process.env.NEXT_PUBLIC_CANDY_MACHINE_CONFIG),
      treasury: new anchor.web3.PublicKey(process.env.NEXT_PUBLIC_TREASURY_ADDRESS),
      connection: new anchor.web3.Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_HOST),
      candyMachineId: new anchor.web3.PublicKey(process.env.NEXT_PUBLIC_CANDY_MACHINE_ID),
    });
  }, [setCandyMachineConfiguration]);

  useEffect(() => {
    if (!wallet) {
      return;
    }
    refreshCandyMachineState();
  }, [wallet, refreshCandyMachineState]);

  return null;
};

export default CandyMachineProvider;

export const useCandyMachineState = () => useRecoilValue(candyMachineStateAtomState);

export const useCandyMachineConfiguration = () =>
  useRecoilValue(candyMachineConfigurationAtomState);

export const useRefreshCandyMachineState = () => useRefreshCandyMachineStateCallback();
