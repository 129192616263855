import { CommunityAddress } from '../library/DisplayAddress.js';
import TwitterIcon from '../icons/TwitterIcon.js';
import DiscordIcon from '../icons/DiscordIcon.js';

const Footer = () => {
  return (
    <div className="mt-10 mb-20">
      <div className="pb-6 flex flex-col items-center">
        <div className="text-white">
          <a
            href="https://twitter.com/LuckyAntsColony"
            target="_blank"
            rel="noreferrer"
            className="pl-1 flex items-center"
          >
            <TwitterIcon className="text-blue-400 h-8 w-8 pr-3" /> Follow us on Twitter
            <TwitterIcon className="text-blue-400 h-8 w-8 pl-3" />
          </a>
        </div>
        <div className="text-white pt-6">
          <a
            href="https://discord.gg/B3ENT4N4vX"
            target="_blank"
            rel="noreferrer"
            className="pl-1 flex items-center"
          >
            <DiscordIcon className="text-blue-400 h-8 w-8 pr-3" /> Join us on Discord
            <DiscordIcon className="text-blue-400 h-8 w-8 pl-3" />
          </a>
        </div>
      </div>
      <div className="md:hidden m-10" />
    </div>
  );
};

export default Footer;
