import { useEffect, useState } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

const countDownTimeState = atom({
  key: 'countDownTimeState',
  default: { days: 0, hours: 0, minutes: 0, seconds: 0 },
});

const countDownTargetDateState = atom({
  key: 'countDownTargetDateState',
  default: undefined,
});

const isTargetDateReachedState = atom({
  key: 'isTargetDateReachedState',
  default: false,
});

const TIME_UNIT = {
  DAY: 1000 * 60 * 60 * 24,
  HOUR: 1000 * 60 * 60,
  MINUTE: 1000 * 60,
  SECOND: 1000,
};

const CountDownTimerProvider = () => {
  const [timeRemaining, setTimeRemaining] = useState();
  const [countDownTime, setCountDownTime] = useRecoilState(countDownTimeState);
  const [targetDate, setTargetDate] = useRecoilState(countDownTargetDateState);
  const [isTargetDateReached, setIsTargetDateReached] = useRecoilState(isTargetDateReachedState);

  useEffect(() => {
    setTargetDate(process.env.NEXT_PUBLIC_CANDY_START_DATE);
  }, [setTargetDate]);

  useEffect(() => {
    if (!targetDate) {
      return;
    }

    const timerInterval = setInterval(() => {
      const remaining = targetDate - Math.trunc(Date.now());
      setTimeRemaining(remaining);
      setIsTargetDateReached(remaining <= 0);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [targetDate, setIsTargetDateReached]);

  useEffect(() => {
    if (!timeRemaining || isNaN(timeRemaining)) {
      return;
    }

    const daysDiff = Math.floor(timeRemaining / TIME_UNIT.DAY);
    const hoursDiff = Math.floor((timeRemaining - daysDiff * TIME_UNIT.DAY) / TIME_UNIT.HOUR);
    const minutesDiff = Math.floor(
      (timeRemaining - daysDiff * TIME_UNIT.DAY - hoursDiff * TIME_UNIT.HOUR) / TIME_UNIT.MINUTE
    );
    const secondsDiff = Math.floor(
      (timeRemaining -
        daysDiff * TIME_UNIT.DAY -
        hoursDiff * TIME_UNIT.HOUR -
        minutesDiff * TIME_UNIT.MINUTE) /
        TIME_UNIT.SECOND
    );

    setCountDownTime({
      days: daysDiff,
      hours: hoursDiff,
      minutes: minutesDiff,
      seconds: secondsDiff,
    });
  }, [timeRemaining, setCountDownTime]);

  return null;
};

export default CountDownTimerProvider;

export const useCountDownTime = () => useRecoilValue(countDownTimeState);

export const useCountDownTargetDate = () => useRecoilValue(countDownTargetDateState);

export const useCountDownTargetDateReached = () => useRecoilValue(isTargetDateReachedState);
