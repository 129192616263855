const FancyContainer = ({ children, className = '', id }) => {
  return (
    <div
      id={id}
      className={`w-11/12 rounded-lg text-white ${className} p-4 md:p-6 lg:p-10 m-2 md:m-4`}
      style={{ border: '1px solid #20162c', backgroundColor: '#191629' }}
    >
      {children}
    </div>
  );
};

export default FancyContainer;
