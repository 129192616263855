import { useCallback, useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import MenuIcon from '../icons/MenuIcon.js';
import TwitterIcon from '../icons/TwitterIcon.js';
import DiscordIcon from '../icons/DiscordIcon.js';
import WalletButton from '../library/WalletButton.js';

const onNavigate = (id) => document.getElementById(id).scrollIntoView();

const LINKS = [
  {
    id: 'armadillos',
    text: 'Missing',
    anchorProps: {
      href: '/armadillos',
    },
  },
  {
    id: 'jailbreak',
    text: 'JailBreak',
    anchorProps: {
      href: '/jailbreak',
    },
  },
  {
    id: 'lore',
    text: 'Lore',
    anchorProps: {
      href: '/#lore',
    },
  },
  {
    id: 'roadmap',
    text: 'Roadmap',
    anchorProps: {
      href: '/#roadmap',
    },
  },
  {
    id: 'faqs',
    text: 'FAQs',
    anchorProps: {
      href: '/#faqs',
    },
  },
  {
    id: 'rarity',
    text: 'Rarity',
    anchorProps: {
      href: '/#rarity',
    },
  },
  {
    id: 'team',
    text: 'Team',
    anchorProps: {
      href: '/#team',
    },
  },
];

const EXTERNAL_LINKS = [
  {
    id: 'twitter',
    text: <TwitterIcon className="text-blue-400 h-8 w-8 pr-3" />,
    anchorProps: {
      href: 'https://twitter.com/LuckyAntsColony',
      target: '_blank',
      rel: 'noreferrer',
    },
  },
  {
    id: 'discord',
    text: <DiscordIcon className="text-blue-400 h-8 w-8 pr-3" />,
    anchorProps: {
      href: 'https://discord.gg/B3ENT4N4vX',
      target: '_blank',
      rel: 'noreferrer',
    },
  },
];

const MOBILE_EXTERNAL_LINKS = [
  {
    id: 'twitter',
    text: (
      <span className="flex items-center pr-2">
        <TwitterIcon className="text-blue-400 h-8 w-8 pr-1" /> Twitter
      </span>
    ),
    anchorProps: {
      href: 'https://twitter.com/LuckyAntsColony',
      target: '_blank',
      rel: 'noreferrer',
    },
  },
  {
    id: 'discord',
    text: (
      <span className="flex items-center">
        <DiscordIcon className="text-blue-400 h-8 w-8 pr-1" /> Discord
      </span>
    ),
    anchorProps: {
      href: 'https://discord.gg/B3ENT4N4vX',
      target: '_blank',
      rel: 'noreferrer',
    },
  },
];

const NavigationBar = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const path = router.asPath;
    const id = /[^\/]*$/.exec(path)[0];
    const element = window.document.getElementById(id);
    if (element) {
      onNavigate(id);
    }
  }, [router]);

  const onToggle = useCallback(() => setIsOpen((current) => !current), []);

  return (
    <>
      {isOpen && <div className="absolute w-full h-full z-10" onClick={onToggle} />}
      <nav className="w-full shadow-md p-2 md:p-3" style={{ borderBottom: '1px solid #20162c' }}>
        <div className="flex justify-center">
          <div className="hidden lg:flex items-center space-x-1 justify-between w-full 2xl:w-7/12">
            <Link href="/" passHref={true}>
              <span className="font-ultra text-2xl text-gray-300 cursor-pointer whitespace-nowrap pr-4">
                Lucky Ants
              </span>
            </Link>
            <div className="flex items-center">
              {LINKS.map(({ id, text, anchorProps }) => (
                <Link key={id} {...anchorProps}>
                  <p className="hidden sm:block font-serif font-black text-lg sm:text-xl text-white hover:text-gray-200 cursor-pointer pr-6">
                    {text}
                  </p>
                </Link>
              ))}
              {EXTERNAL_LINKS.map(({ id, text, anchorProps }) => (
                <a key={id} {...anchorProps}>
                  <p className="hidden sm:block font-serif font-black text-lg sm:text-xl text-white hover:text-gray-200 cursor-pointer pr-6">
                    {text}
                  </p>
                </a>
              ))}
            </div>
          </div>

          <div className="lg:hidden flex justify-between items-center w-full pl-2 pr-1">
            <Link href="/" passHref={true}>
              <span className="font-ultra text-2xl text-white flex">Lucky Ants</span>
            </Link>
            <button onClick={onToggle}>
              <MenuIcon className="bg-gray-50 w-8 h-8" />
            </button>
            <div
              className="absolute mobile-menu flex right-0 top-10 w-auto p-6 font-display bg-gray-900 text-xl border-1 border-black-700 rounded-md z-20 shadow"
              style={{
                visibility: `${isOpen ? 'visible' : 'hidden'}`,
              }}
            >
              <ul className="space-y-4 font-bold">
                {LINKS.map(({ id, text, anchorProps }) => (
                  <li key={id} className="flex justify-start items-center">
                    <Link {...anchorProps} passHref={true}>
                      <span className="text-white">{text}</span>
                    </Link>
                  </li>
                ))}
                {MOBILE_EXTERNAL_LINKS.map(({ id, text, anchorProps }) => (
                  <li key={id} className="flex justify-start items-center">
                    <a {...anchorProps} className="text-white">
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
