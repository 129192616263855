import { RecoilRoot } from 'recoil';
import NavigationBar from './NavigationBar.js';
import CandyMachineProvider from '../library/CandyMachineProvider.js';
import CountDownTimerProvider from '../library/CountDownTimerProvider.js';

const Scaffolding = ({ children }) => {
  return (
    <RecoilRoot>
      <div className="flex flex-col h-screen">
        <NavigationBar />
        <div className="flex-1 block fancy-scroll-bar overflow-x-hidden">
          <div className="flex flex-col items-center justify-center">
            <div
              className="flex flex-col items-center justify-center"
              style={{ maxWidth: '100rem' }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      <CandyMachineProvider />
      <CountDownTimerProvider />
    </RecoilRoot>
  );
};

export default Scaffolding;
